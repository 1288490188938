import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import ConfirmationPage from "./pages/ConfirmationPage/ConfirmationPage";
import RegistrationCompletionPage from "./pages/RegistrationCompletionPage/RegistrationCompletionPage";
import MainPage from "./pages/MainPage/MainPage";
import { Provider } from "react-redux";
import ProtectedRoute from "./redux/ProtectedRoute";
import PreviewPage from "./pages/PreviewPage/PreviewPage";
import store from "./redux/store";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import ResetPasswordStep1Page from "./pages/ResetPasswordStep1Page/ResetPasswordStep1Page";
import ConfirmationPasswordCodePage from "./pages/ConfirmationPasswordCodePage/ConfirmationPasswordCodePage";
import SetNewPasswordPage from "./pages/SetNewPasswordPage/SetNewPasswordPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import YandexAuthComplete from "./pages/YandexAuthComplete/YandexAuthComplete";

function App() {
  return (
    <GoogleOAuthProvider clientId="1075420085911-ke6khrff63rec5jclbbkc1ms6pki31n4.apps.googleusercontent.com">
      <Provider store={store}>
        <div className="App">
          <Router>
            <Routes>
              <Route path="/preview-page" index element={<PreviewPage />} />
              <Route path="/login-page" index element={<LoginPage />} />
              <Route path="/confirm-page" element={<ConfirmationPage />} />
              <Route path="/registration-page" element={<RegistrationPage />} />
              <Route
                path="/registration-completion-page"
                element={<RegistrationCompletionPage />}
              />
              <Route
                path="/reset-password-step1-page"
                element={<ResetPasswordStep1Page />}
              />
              <Route
                path="/confirmation-password-code-page"
                element={<ConfirmationPasswordCodePage />}
              />
              <Route
                path="/set-new-password-page"
                element={<SetNewPasswordPage />}
              />
              <Route
                  path="/auth/complete/yandex-oauth2/"
                  element={<YandexAuthComplete />}
              />

              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <MainPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </div>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
