import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import { accountsApi } from "./apiService";
import emailSlice from "./slices/emailSlice";
import fileSlice from "./slices/fileSlice";
import tableSlice from "./slices/tableSlice";
import userIDSlice from "./slices/userIDSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  email: emailSlice,
  file: fileSlice,
  table: tableSlice,
  userID: userIDSlice,

  [accountsApi.reducerPath]: accountsApi.reducer, // Подключаем profilesApi
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
