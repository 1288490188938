import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmailState {
  email: string | null;
}

const initialState: EmailState = {
  email: localStorage.getItem("email"), // Получаем email из localStorage, если он есть
};

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      localStorage.setItem("email", action.payload); // Сохраняем в localStorage
    },
    clearEmail: (state) => {
      state.email = null;
      localStorage.removeItem("email"); // Удаляем из localStorage
    },
  },
});

export const { setUserEmail, clearEmail } = emailSlice.actions;

export default emailSlice.reducer;
