import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileState {
  selectedFileId: string | number | null;
}

const initialState: FileState = {
  selectedFileId: null,
};

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setSelectedFileId: (state, action: PayloadAction<string | number>) => {
      state.selectedFileId = action.payload;
    },
    clearSelectedFileId: (state) => {
      state.selectedFileId = null;
    },
  },
});

export const { setSelectedFileId, clearSelectedFileId } = fileSlice.actions;

export default fileSlice.reducer;
