import { configureStore } from "@reduxjs/toolkit";
import { accountsApi } from "./apiService";
import rootReducer from "./rootReducer";
import { setAccessToken, setRefreshToken } from "./slices/authSlice";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(accountsApi.middleware),
});

// Типы для TypeScript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const accessToken = localStorage.getItem("accessToken");
const refreshToken = localStorage.getItem("refreshToken");
//const selectedUserId = localStorage.getItem("selectedUserId");
if (accessToken) {
  store.dispatch(setAccessToken(accessToken));
}
if (refreshToken) {
  store.dispatch(setRefreshToken(refreshToken));
}

export default store;
