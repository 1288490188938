import {
  BaseQueryArg,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import {
  AuthResponse,
  ConfirmEmailRequest,
  confirmPasswordRequest,
  FilesListRequest,
  LoginRequest,
  logoutRequest,
  RegisterRequest,
  ResetPasswordRequest,
  StandartResponse,
  UserProfileRequest,
  VerifyForgotPasswordCodeRequest,
} from "./apiRequestsTypes";
import { setAccessToken } from "./slices/authSlice";

const BASE_API_URL = "https://mu.daark-team.ru/";

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}`,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: "api/token/refresh/",
          method: "POST",
          body: { refresh: refreshToken },
        },
        api,
        extraOptions,
      );

      if (refreshResult.data) {
        const { access } = refreshResult.data as AuthResponse;

        if (access) {
          localStorage.setItem("accessToken", access);
          api.dispatch(setAccessToken(access));

          // Повторный запрос после обновления токена
          result = await baseQuery(args, api, extraOptions);
        } else {
          console.error("Ошибка: обновление токена не вернуло access-токен");
        }
      } else {
        console.error("Ошибка: не удалось обновить токен");
      }
    }
  }

  return result;
};

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    registerUser: builder.mutation<any, RegisterRequest>({
      query: (registerData) => ({
        url: "accounts/register/",
        method: "POST",
        body: registerData,
      }),
    }),
    confirmUserEmail: builder.mutation<any, ConfirmEmailRequest>({
      query: (confirmEmailData) => ({
        url: "accounts/verify_email/",
        method: "POST",
        body: confirmEmailData,
      }),
    }),
    repeatConfirmUserEmail: builder.mutation<any, RegisterRequest>({
      query: (confirmEmailData) => ({
        url: "accounts/resend-verification-code/",
        method: "POST",
        body: confirmEmailData,
      }),
    }),
    loginUser: builder.mutation<any, LoginRequest>({
      query: (loginData) => ({
        url: "api/token/",
        method: "POST",
        body: loginData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    logoutUser: builder.mutation<any, logoutRequest>({
      query: (logoutData) => ({
        url: "accounts/logout/",
        method: "POST",
        body: logoutData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getMain: builder.query<any, void>({
      query: () => ({
        url: "accounts/main/",
        method: "GET",
      }),
    }),
    setPassword: builder.mutation<any, confirmPasswordRequest>({
      query: (confirmPasswordData) => ({
        url: "accounts/set_password/",
        method: "POST",
        body: confirmPasswordData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getUsers: builder.query<any, void>({
      query: () => ({
        url: "accounts/users/",
        method: "GET",
      }),
    }),
    uploadFile: builder.mutation<any, FormData>({
      query: (fileData) => ({
        url: "files/upload/",
        method: "POST",
        body: fileData,
      }),
    }),
    getFilesList: builder.query<FilesListRequest, void>({
      query: () => ({
        url: "files/my_files/",
        method: "GET",
      }),
    }),
    deleteFile: builder.mutation<StandartResponse, string>({
      query: (id) => ({
        url: `files/delete/${id}/`,
        method: "DELETE",
      }),
    }),
    downloadFile: builder.query<Blob, string>({
      query: (id) => ({
        url: `files/download/${id}/`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      // Если нужно, можно добавить дополнительные параметры
      // например, для логирования или обработки ошибки
      transformResponse: (response: Blob) => {
        console.log("File downloaded successfully");
        return response; // Возвращаем Blob, если нужно
      },
    }),
    downloadTranscription: builder.query<string, string>({
      query: (id) => ({
        url: `files/download_transcription/${id}/`,
        method: "GET",
        responseHandler: (response) => response.text(), // обрабатываем ответ как текст
      }),
      transformResponse: (response: string) => {
        console.log("Transcription downloaded successfully");
        return response; // Возвращаем текстовую транскрипцию
      },
    }),
    getUserProfile: builder.query<UserProfileRequest, void>({
      query: () => ({
        url: "files/user_info/",
        method: "GET",
      }),
    }),
    forgotPasswordCodeRequest: builder.mutation<
      StandartResponse,
      RegisterRequest
    >({
      query: (codeData) => ({
        url: "accounts/forgot-password/",
        method: "POST",
        body: codeData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    verifyForgotPasswordCode: builder.mutation<
      any,
      VerifyForgotPasswordCodeRequest
    >({
      query: (codeData) => ({
        url: "accounts/verify-forgot-password-code/",
        method: "POST",
        body: codeData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    resetPasswordRequest: builder.mutation<
      StandartResponse,
      ResetPasswordRequest
    >({
      query: (resetPasswordData) => ({
        url: "accounts/reset-password/",
        method: "POST",
        body: resetPasswordData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useSetPasswordMutation,
  useConfirmUserEmailMutation,
  useUploadFileMutation,
  useGetFilesListQuery,
  useDeleteFileMutation,
  useDownloadFileQuery,
  useDownloadTranscriptionQuery,
  useGetUserProfileQuery,
  useRepeatConfirmUserEmailMutation,
  useForgotPasswordCodeRequestMutation,
  useVerifyForgotPasswordCodeMutation,
  useResetPasswordRequestMutation,
} = accountsApi;
