const BASE_API_URL = "https://mu.daark-team.ru/";

export const refreshAccessToken = async (): Promise<string | null> => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) {
    console.error("Ошибка: отсутствует refresh-токен");
    return null;
  }

  try {
    const response = await fetch(`${BASE_API_URL}api/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
      console.error("Ошибка при обновлении токена:", response.statusText);
      return null;
    }

    const data = await response.json();
    if (data.access) {
      localStorage.setItem("accessToken", data.access);
      return data.access;
    } else {
      console.error("Ошибка: отсутствует access-токен в ответе");
      return null;
    }
  } catch (error) {
    console.error("Ошибка при запросе обновления токена:", error);
    return null;
  }
};
