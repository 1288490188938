import React from "react";
import { YandexButton } from "../styles/LoginPage.styles"; // Стиль кнопки, аналогичный GoogleButton

interface YandexLoginButtonProps {
  onSuccess: (tokenResponse: any) => void;
  onError: () => void;
}

const YandexLoginButton: React.FC<YandexLoginButtonProps> = ({
  onSuccess,
  onError,
}) => {
  const handleYandexLogin = async () => {
    try {
      // Открываем окно авторизации Яндекса
      const clientId = "81e338006fc040c782f04a59429ef02e";
      // const redirectUri =
      //   "https://mu.daark-team.ru/auth/complete/yandex-oauth2/"; // Редирект-адрес
      const redirectUri =
          // "http://localhost:3000/auth/complete/yandex-oauth2/"; // Редирект-адрес
          "https://mu.daark-team.ru/auth/complete/yandex-oauth2/"; // Редирект-адрес

      const state = encodeURIComponent(
        Math.random().toString(36).substring(2) + Date.now().toString(36),
      );
      const yandexAuthUrl = `https://oauth.yandex.ru/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
      // const yandexAuthUrl = `https://oauth.yandex.ru/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}`;

      // Ожидаем завершения авторизации
      const popup = window.open(
        yandexAuthUrl,
        "_blank",
        "width=500,height=600",
      );

      if (!popup) {
        throw new Error("Не удалось открыть окно авторизации.");
      }

      // Ждём ответа от Яндекса через redirect_uri
  //     const interval = setInterval(() => {
  //       try {
  //         if (popup.closed) {
  //           clearInterval(interval);
  //           throw new Error("Пользователь закрыл окно авторизации.");
  //         }
  //
  //         const popupUrl = popup.location.href;
  //
  //         // Проверяем, вернулся ли пользователь на redirect_uri
  //         if (popupUrl.includes(redirectUri)) {
  //           const urlParams = new URLSearchParams(
  //             popupUrl.split("#")[1], // Токен приходит в части URL после #
  //           );
  //           const tokenResponse = {
  //             access_token: urlParams.get("access_token"),
  //             token_type: urlParams.get("token_type"),
  //             expires_in: urlParams.get("expires_in"),
  //             state: urlParams.get("state"),
  //           };
  //
  //           popup.close();
  //           clearInterval(interval);
  //
  //           if (tokenResponse.access_token) {
  //             onSuccess(tokenResponse); // Передаём токен наверх
  //           } else {
  //             onError();
  //           }
  //         }
  //       } catch (e) {
  //         // Иначе продолжаем ожидать
  //       }
  //     }, 500);
  //   } catch (error) {
  //     console.error("Ошибка авторизации через Яндекс:", error);
  //     onError();
  //   }
  // };

      const handleMessage = (event: MessageEvent) => {
        if (event.origin !== window.location.origin) {
          return;
        }

        const { access_token, state: returnedState } = event.data;

        if (returnedState === state && access_token) {
          onSuccess({ access_token });
        } else {
          onError();
        }

        window.removeEventListener("message", handleMessage);
      };

      window.addEventListener("message", handleMessage);
    } catch (error) {
      console.error("Ошибка авторизации через Яндекс:", error);
      onError();
    }
  };

  return (
    <YandexButton onClick={() => handleYandexLogin()}>
      Продолжить с помощью Яндекса
    </YandexButton>
  );
};

export default YandexLoginButton;
