import React from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { GoogleButton } from "../styles/LoginPage.styles";

interface GoogleLoginButtonProps {
  onSuccess: (tokenResponse: any) => void;
  onError: () => void;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  onSuccess,
  onError,
}) => {
  const handleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      onSuccess(tokenResponse); // Передаем данные наверх
    },
    onError: () => {
      onError(); // Обрабатываем ошибку
    },
  });

  return (
    <GoogleButton onClick={() => handleLogin()}>
      Продолжить с помощью Google
    </GoogleButton>
  );
};

export default GoogleLoginButton;
