import React, { useState } from "react";
import {
  Card,
  Container,
  Footer,
  Input,
  InputGroup,
  InputName,
  RegisterLink,
  SubmitButton,
  Title,
} from "../../components/styles/LoginPage.styles";
import { useNavigate } from "react-router-dom";
import {
  Logo,
  LogoIcon,
  LogoText,
} from "../../components/styles/PreviewPage.styles";
import {
  useConfirmUserEmailMutation,
  useRepeatConfirmUserEmailMutation,
} from "../../redux/apiService";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";

const ConfirmationPage: React.FC = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const email = useSelector((state: RootState) => state.email.email);
  console.log(email); // Доступ к email из Redux Store
  const [confirmUserEmail, { isLoading }] = useConfirmUserEmailMutation();
  const [resendCode] = useRepeatConfirmUserEmailMutation();
  const navigate = useNavigate();

  const handleConfirmationClick = async () => {
    setError(null); // Сбрасываем ошибку перед новым запросом
    try {
      const response = await confirmUserEmail({ code }).unwrap();

      if (response.status_code === 200) {
        // Перенаправляем пользователя
        navigate("/registration-completion-page");
      } else {
        setError("Ошибка авторизации. Проверьте данные и попробуйте снова.");
      }
    } catch (err) {
      setError(
        "Не удалось войти. Проверьте почту и пароль или повторите попытку позже.",
      );
    }
  };

  const handleResendClick = async () => {
    setError(null);
    if (!email) {
      setError(
        "Ошибка: Email не найден. Пожалуйста, начните регистрацию сначала.",
      );
      return;
    }
    try {
      const response = await resendCode({ email }).unwrap();

      if (response.status_code === 200) {
        console.log(response);
      }
    } catch (err) {
      setError("Что-то пошло не так. Попробуйте позже.");
    }
  };

  return (
    <Container>
      <Card>
        <Logo>
          <LogoIcon src="/assets/icons/Subtract.svg" />
          <LogoText>muDAARK.io</LogoText>
        </Logo>
        <Title>Мы отправили проверочный код на вашу почту</Title>
        <InputGroup>
          <InputName>Код</InputName>
          <Input
            type="code"
            placeholder="Код"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <SubmitButton onClick={handleConfirmationClick}>
            Подтвердить
          </SubmitButton>
          <Footer>
            Не пришёл код?{" "}
            <RegisterLink onClick={handleResendClick}>
              Отправить ещё раз
            </RegisterLink>
          </Footer>
        </InputGroup>
      </Card>
    </Container>
  );
};

export default ConfirmationPage;
