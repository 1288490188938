import styled from "styled-components";

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: #ffffff;
  color: #002033;
  height: 10%;
  padding: 0 37px 0 37px;
`;

export const PageTitle = styled.h1`
  //font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const UserMail = styled.h2`
  font-size: 16px;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 10px;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
  cursor: pointer;
`;
