import React, { useCallback, useState } from "react";
import {
  Card,
  Container,
  Footer,
  GoogleButton,
  Input,
  InputGroup,
  InputName,
  OrText,
  RegisterLink,
  SubmitButton,
  Title,
} from "../../components/styles/LoginPage.styles";
import { useNavigate } from "react-router-dom";

import { useRegisterUserMutation } from "../../redux/apiService";
import {
  Logo,
  LogoIcon,
  LogoText,
} from "../../components/styles/PreviewPage.styles";
import { useDispatch } from "react-redux";
import { setUserEmail } from "../../redux/slices/emailSlice";

const RegistrationPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const [error, setError] = useState<string | null>(null);

  const [registerUser, { isLoading }] = useRegisterUserMutation();

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login-page");
  };

  const handleRegister = async () => {
    setError(null); // Сбрасываем ошибку перед новым запросом
    try {
      const response = await registerUser({ email }).unwrap();

      if (response.status_code === 200) {
        dispatch(setUserEmail(email)); // Сохраняем email в Redux и localStorage

        navigate("/confirm-page");
      } else {
        setError("Ошибка авторизации. Проверьте данные и попробуйте снова.");
      }
    } catch (err) {
      setError(
        "Не удалось войти. Проверьте почту и пароль или повторите попытку позже.",
      );
    }
  };

  return (
    <Container>
      <Card>
        <Logo>
          <LogoIcon src="/assets/icons/Subtract.svg" />
          <LogoText>muDAARK.io</LogoText>
        </Logo>
        <Title>Регистрация</Title>
        <InputGroup>
          <GoogleButton>Продолжить с помощью Google</GoogleButton>
          <OrText>ИЛИ</OrText>
          <InputName>Почта</InputName>
          <Input
            type="email"
            placeholder="Адрес электронной почты"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {error && <p style={{ color: "red" }}>{error}</p>}
          <SubmitButton onClick={handleRegister} disabled={isLoading}>
            Продолжить с почтой
          </SubmitButton>
          <Footer>
            Уже есть аккаунт?
            <RegisterLink onClick={handleLogin}>Войти</RegisterLink>
          </Footer>
        </InputGroup>
      </Card>
    </Container>
  );
};

export default RegistrationPage;
