import React, { useState } from "react";
import {
  ModalContainer,
  ModalCard,
  Header,
  CloseButton,
  DropArea,
  DragText,
  HintText,
  UploadOptions,
  FileDetails,
  FileInfo,
  FileMeta,
  FileName,
  Form,
  Label,
  Input,
  Select,
  SubmitButton,
  FromDeviceButton,
  FromLinkButton,
  DeleteButton,
  FileIcon,
  DeleteIcon,
  StyledInput,
  Loader,
} from "./styles/FileUploadModal.styles";
import { useUploadFileMutation } from "../redux/apiService";
import { triggerRefetch } from "../redux/slices/tableSlice";
import { useDispatch } from "react-redux";

interface FileUploadModalProps {
  closeModal: () => void;
}

const MAX_FILE_SIZE_MB = 1024; // Максимальный размер файла в МБ

const FileUploadModal: React.FC<FileUploadModalProps> = ({ closeModal }) => {
  const [step, setStep] = useState(1);
  const [fileList, setFileList] = useState<File[]>([]);
  const [fileName, setFileName] = useState<string | undefined>();
  const [speakers, setSpeakers] = useState<number>(1);
  const [language, setLanguage] = useState<string>("russian");
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Для отображения ошибок
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  const [uploadFile, { isLoading, isError, isSuccess }] =
    useUploadFileMutation();

  const handleNextStep = () => {
    if (fileList.length > 0) {
      setStep(2);
    }
  };

  const isFileSizeValid = (file: File) =>
    file.size <= MAX_FILE_SIZE_MB * 1024 * 1024;

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const validFiles = files.filter(isFileSizeValid);
    const invalidFiles = files.filter((file) => !isFileSizeValid(file));

    if (invalidFiles.length > 0) {
      setErrorMessage(
        "Один или несколько файлов превышают 1 ГБ и не будут добавлены.",
      );
    } else {
      setErrorMessage(null);
    }

    if (validFiles.length > 0) {
      setFileList((prevFiles) => [...prevFiles, ...validFiles]);
      setStep(2);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files);
      const validFiles = filesArray.filter(isFileSizeValid);
      const invalidFiles = filesArray.filter((file) => !isFileSizeValid(file));

      if (invalidFiles.length > 0) {
        setErrorMessage(
          "Один или несколько файлов превышают 1 ГБ и не будут добавлены.",
        );
      } else {
        setErrorMessage(null);
      }

      if (validFiles.length > 0) {
        setFileList(validFiles);
        setStep(2);
      }
    }
  };

  const handleDeleteFile = (file: File) => {
    setFileList(fileList.filter((f) => f !== file));
    if (fileList.length === 1) {
      setStep(1);
    }
  };

  const handleSubmit = async () => {
    if (fileList.length === 0) return;

    const formData = new FormData();
    formData.append("file", fileList[0]);
    formData.append("name", fileName || fileList[0].name);
    formData.append("speakers", speakers.toString());
    formData.append("language", language);

    setStep(3);

    try {
      await uploadFile(formData).unwrap();
      setUploadMessage("Файл успешно загружен!");
      dispatch(triggerRefetch());
      setStep(4);
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
      setUploadMessage("Ошибка загрузки. Попробуйте снова.");
      setStep(4);
    }
  };

  const currentFile = fileList[0];
  const fileMetaDisplay = currentFile
    ? {
        name: currentFile.name,
        size: (currentFile.size / (1024 * 1024)).toFixed(2) + " МБ",
        lastModified: new Date(currentFile.lastModified).toLocaleString(),
      }
    : null;

  return (
    <ModalContainer>
      <ModalCard>
        <Header>
          <h3>Загрузка аудио или видео файла</h3>
          <CloseButton onClick={closeModal}>✖</CloseButton>
        </Header>

        {step === 1 && (
          <>
            <DropArea
              onDrop={onDrop}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
            >
              <DragText>
                Перетащите файлы или <span>загрузите</span>
              </DragText>
              <HintText>Поддерживаемые форматы: MP4, MP3, M4A, WAV</HintText>
              <HintText>Максимально допустимый объём файла - 1ГБ</HintText>
              <StyledInput type="file" multiple onChange={onChange} />
            </DropArea>
            <UploadOptions>
              <FromDeviceButton>
                С устройства
                <StyledInput type="file" multiple onChange={onChange} />
              </FromDeviceButton>
              <FromLinkButton>По ссылке</FromLinkButton>
            </UploadOptions>
            {errorMessage && (
              <p style={{ color: "red", marginTop: "10px" }}>{errorMessage}</p>
            )}
            <SubmitButton
              onClick={handleNextStep}
              disabled={fileList.length === 0}
            >
              Загрузить файл
            </SubmitButton>
          </>
        )}

        {step === 2 && currentFile && (
          <>
            <DropArea>
              <DragText>
                Перетащите файлы или <span>загрузите</span>
              </DragText>
              <HintText>Поддерживаемые форматы: MP4, MP3, M4A, WAV</HintText>
              <HintText>Максимально допустимый объём файла - 1ГБ</HintText>
            </DropArea>
            <UploadOptions>
              <FromDeviceButton>
                С устройства
                <StyledInput type="file" multiple onChange={onChange} />
              </FromDeviceButton>
              <FromLinkButton>По ссылке</FromLinkButton>
            </UploadOptions>
            <FileDetails>
              <FileInfo>
                <FileIcon src="/assets/icons/mp3.svg" alt="File" />
                <div>
                  <FileName>{fileMetaDisplay?.name}</FileName>
                  <FileMeta>
                    {fileMetaDisplay?.size} · {fileMetaDisplay?.lastModified}
                  </FileMeta>
                </div>
                <DeleteButton onClick={() => handleDeleteFile(currentFile)}>
                  <DeleteIcon src="/assets/icons/trash_2.svg" alt="Удалить" />
                </DeleteButton>
              </FileInfo>
            </FileDetails>
            <Form>
              <Label>Название видео</Label>
              <Input
                type="text"
                placeholder="Название"
                defaultValue={fileMetaDisplay?.name}
                onChange={(e) => setFileName(e.target.value)}
              />
              <Label>Кол-во спикеров</Label>
              <Input
                type="number"
                placeholder="Количество"
                defaultValue="1"
                onChange={(e) => setSpeakers(Number(e.target.value))}
              />
              <Label>Язык</Label>
              <Select
                defaultValue="russian"
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="russian">Русский</option>
                <option value="english">Английский</option>
                <option value="other">Другой</option>
              </Select>
            </Form>
            <SubmitButton onClick={handleSubmit} disabled={isLoading}>
              Загрузить файл
            </SubmitButton>
            {isError && <p style={{ color: "red" }}>Ошибка загрузки файла.</p>}
            {isSuccess && (
              <p style={{ color: "green" }}>Файл успешно загружен!</p>
            )}
          </>
        )}
        {step === 3 && (
          <>
            {/* Шаг 3: Прелоадер */}
            <Loader />
            <p style={{ textAlign: "center" }}>
              <h3>Идет загрузка файла...</h3>
              <HintText>
                Не покидайте и не закрывайте страницу до окончания загрузки
              </HintText>
            </p>
          </>
        )}
        {step === 4 && (
          <>
            <p
              style={{
                textAlign: "center",
                color: uploadMessage?.includes("успешно") ? "green" : "red",
                fontSize: "20px",
              }}
            >
              {uploadMessage || "Неизвестная ошибка."}
            </p>
            <SubmitButton onClick={closeModal}>
              {uploadMessage?.includes("успешно")
                ? "Закрыть"
                : "Попробовать снова"}
            </SubmitButton>
          </>
        )}
      </ModalCard>
    </ModalContainer>
  );
};

export default FileUploadModal;
