import React from "react";
import Layout from "../../components/Layout/Layout";
import MyFilesTable from "./components/MyFilesTable";
import {
  Avatar,
  PageHeader,
  PageTitle,
  UserMail,
} from "../../components/styles/MainPage.styles";
import { useGetUserProfileQuery } from "../../redux/apiService";

const MainPage: React.FC = () => {
  const { data: userData, isLoading } = useGetUserProfileQuery();
  return (
    <Layout>
      <PageHeader>
        <PageTitle>Мои файлы</PageTitle>
        <UserMail>
          <Avatar src="/assets/icons/avatar.svg" />
          {/*daark@gmail.com*/}
          {userData?.user_info.email}
        </UserMail>
      </PageHeader>
      <MyFilesTable />
    </Layout>
  );
};

export default MainPage;
