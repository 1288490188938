import React, { useState } from "react";
import {
  Actions,
  Cell,
  DeleteButton,
  DownloadButton,
  EmptyContainer,
  EmptyIcon,
  EmptyText,
  HeaderItem,
  Link,
  Name,
  TableBody,
  TableContainer,
  TableHeader,
  TableRow,
} from "../../../components/styles/MyFilesTable.styles";
import StatusBadge from "./StatusBadge/StatusBadge";
import {
  useDeleteFileMutation,
  // useDownloadFileQuery,
  // useDownloadTranscriptionQuery,
  useGetFilesListQuery,
} from "../../../redux/apiService";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFileId } from "../../../redux/slices/fileSlice";
import { RootState } from "../../../redux/rootReducer";
import { resetRefetch } from "../../../redux/slices/tableSlice";
import FileUploadModal from "../../../components/FileUploadModal";
import { UploadButton } from "../../../components/styles/Layout.styles";
import { refreshAccessToken } from "../../../api/requests";

const MyFilesTable: React.FC = () => {
  const selectedFileId = useSelector(
    (state: RootState) => state.file.selectedFileId,
  );
  console.log("Selected File ID:", selectedFileId);
  const BASE_API_URL = "https://mu.daark-team.ru/";

  const dispatch = useDispatch();
  const shouldRefetch = useSelector(
    (state: RootState) => state.table.shouldRefetch,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const {
    data: filesResponse,
    isLoading,
    isError,
    refetch,
  } = useGetFilesListQuery();

  React.useEffect(() => {
    if (shouldRefetch) {
      refetch();
      dispatch(resetRefetch());
    }
  }, [shouldRefetch, refetch, dispatch]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      refetch(); // Вызываем refetch каждые 30 секунд
    }, 30000);

    return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
  }, [refetch]);

  const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation();

  // const {
  //   data: downloadData,
  //   error: downloadError,
  //   isLoading: isDownloading,
  // } = useDownloadFileQuery(
  //   (selectedFileId && selectedFileId.toString()) || "",
  //   {
  //     skip: !selectedFileId, // Не выполнять запрос, если нет выбранного файла
  //   },
  // );
  //
  // const {
  //   data: transcriptionData,
  //   isLoading: isTranscriptionLoading,
  //   error: transcriptionError,
  // } = useDownloadTranscriptionQuery(
  //   (selectedFileId && selectedFileId.toString()) || "",
  //   {
  //     skip: !selectedFileId,
  //   },
  // );

  // Обработчик для скачивания файла
  // const handleDownload = (fileId: string, fileName: string) => {
  //   dispatch(setSelectedFileId(fileId));
  //   if (isDownloading) {
  //     console.log("Загрузка файла...");
  //     return;
  //   }
  //
  //   if (downloadError) {
  //     console.error("Ошибка при скачивании файла:", downloadError);
  //     return;
  //   }
  //
  //   if (downloadData) {
  //     // Получаем Blob и создаем ссылку для скачивания
  //     const url = window.URL.createObjectURL(downloadData);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = fileName; // Устанавливаем имя файла
  //     link.click();
  //     window.URL.revokeObjectURL(url); // Освобождаем память
  //   }
  // };
  const handleDownload = async (fileId: string, fileName: string) => {
    try {
      let token = localStorage.getItem("accessToken");

      if (!token) {
        console.error("Ошибка: отсутствует токен доступа");
        return;
      }

      let response = await fetch(`${BASE_API_URL}files/download/${fileId}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        console.warn("Неавторизованный доступ. Попытка обновить токен...");

        const newToken = await refreshAccessToken();
        if (!newToken) {
          console.error("Не удалось обновить токен. Перенаправление на логин.");
          return;
        }

        token = newToken; // Обновляем токен
        response = await fetch(`${BASE_API_URL}files/download/${fileId}/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      if (!response.ok) {
        console.error("Ошибка при скачивании файла:", response.statusText);
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName; // Устанавливаем имя файла
      link.click();
      window.URL.revokeObjectURL(url); // Освобождаем память
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    }
  };

  // const handleDownloadTranscription = (fileId: string, fileName: string) => {
  //   dispatch(setSelectedFileId(fileId)); // Сохраняем id в Redux
  //   if (isTranscriptionLoading) {
  //     console.log("Загрузка транскрипции...");
  //     return;
  //   }
  //
  //   if (transcriptionError) {
  //     console.error("Ошибка при скачивании транскрипции:", transcriptionError);
  //     return;
  //   }
  //
  //   if (transcriptionData) {
  //     // Создаем Blob для текста SRT и скачиваем
  //     const blob = new Blob([transcriptionData], { type: "text/plain" });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = `${fileName}.srt`; // Скачиваем с расширением .srt
  //     link.click();
  //     window.URL.revokeObjectURL(url);
  //   }
  // };
  const handleDownloadTranscription = async (
    fileId: string,
    fileName: string,
  ) => {
    try {
      let token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("Ошибка: отсутствует токен доступа");
        return;
      }

      let response = await fetch(
        `${BASE_API_URL}files/download_transcription/${fileId}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 401) {
        const newToken = await refreshAccessToken();
        if (!newToken) {
          console.error("Не удалось обновить токен. Перенаправление на логин.");
          return;
        }

        token = newToken; // Обновляем токен
        response = await fetch(
          `${BASE_API_URL}files/download_transcription/${fileId}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      }

      if (!response.ok) {
        console.error(
          "Ошибка при скачивании транскрипции:",
          response.statusText,
        );
        return;
      }

      const transcription = await response.text();
      const blob = new Blob([transcription], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${fileName}.txt`; // Устанавливаем имя файла
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Ошибка при скачивании транскрипции:", error);
    }
  };

  if (isLoading) return <p>Загрузка файлов...</p>;
  if (isError) return <p>Ошибка загрузки данных.</p>;
  if (!filesResponse?.data?.length) {
    return (
      <TableContainer>
        <TableHeader>
          <HeaderItem>Имя файла</HeaderItem>
          <HeaderItem>Ссылка на файл</HeaderItem>
          <HeaderItem>Длительность</HeaderItem>
          <HeaderItem>Спикеры</HeaderItem>
          <HeaderItem>Дата создания</HeaderItem>
          <HeaderItem>Статус</HeaderItem>
          <HeaderItem>Действия</HeaderItem>
        </TableHeader>
        <EmptyContainer>
          <EmptyIcon /> {/* Иконка пустой папки */}
          <EmptyText>У вас нет файлов</EmptyText>
          <UploadButton onClick={openModal}>Загрузить файл</UploadButton>
        </EmptyContainer>
        {isModalOpen && <FileUploadModal closeModal={closeModal} />}
      </TableContainer>
    );
  }

  const handleRowClick = (fileId: string | number) => {
    dispatch(setSelectedFileId(fileId)); // Сохраняем id в Redux
  };
  const handleDelete = async (fileId: string) => {
    try {
      // const response = await deleteFile(fileId).unwrap();
      await deleteFile(fileId).unwrap();
      // if (response.status_code === 204) {
      refetch();
      console.log(`File with ID ${fileId} was deleted successfully.`);
      // }
    } catch (error) {
      console.error("Failed to delete the file:", error);
    }
  };

  return (
    <TableContainer>
      <TableHeader>
        <HeaderItem>Имя файла</HeaderItem>
        <HeaderItem>Ссылка на файл</HeaderItem>
        <HeaderItem>Длительность</HeaderItem>
        <HeaderItem>Спикеры</HeaderItem>
        <HeaderItem>Дата создания</HeaderItem>
        <HeaderItem>Статус</HeaderItem>
        <HeaderItem>Действия</HeaderItem>
      </TableHeader>
      <TableBody>
        {filesResponse?.data.map((file) => (
          <TableRow
            key={file.id}
            onClick={() => handleRowClick(file.id.toString())}
          >
            <Cell>
              <Name>{file.name}</Name>
            </Cell>
            <Cell>
              {/*{file.status === "completed" && (*/}
              <Link
                onClick={(e) => {
                  e.stopPropagation(); // Предотвращаем выбор строки
                  handleDownload(file.id.toString(), file.name); // Передаем ID и имя файла
                }}
              >
                {file.name}
              </Link>
              {/*)}*/}
            </Cell>
            <Cell>{file.duration}</Cell>
            <Cell>{file.speakers}</Cell>
            <Cell>{new Date(file.created_at).toLocaleDateString("ru-RU")}</Cell>
            <Cell>
              <StatusBadge>{file.status}</StatusBadge>
            </Cell>
            <Cell>
              <Actions>
                {file.status === "completed" && (
                  <>
                    <DownloadButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownloadTranscription(
                          file.id.toString(),
                          file.name,
                        );
                      }}
                    >
                      Скачать
                    </DownloadButton>
                  </>
                )}
                <DeleteButton
                  onClick={(e) => {
                    e.stopPropagation(); // Предотвращаем выбор строки
                    handleDelete(file.id.toString());
                  }}
                  disabled={isDeleting}
                />
              </Actions>
            </Cell>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
};

export default MyFilesTable;
