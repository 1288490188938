import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserIDState {
  userID: string | null;
}

const initialState: UserIDState = {
  userID: localStorage.getItem("userID"), // Получаем userID из localStorage, если он есть
};

const userIDSlice = createSlice({
  name: "userID",
  initialState,
  reducers: {
    setUserID: (state, action: PayloadAction<number>) => {
      const userIDString = action.payload.toString();
      state.userID = userIDString;
      localStorage.setItem("userID", userIDString);
    },
    clearID: (state) => {
      state.userID = null;
      localStorage.removeItem("userID"); // Удаляем из localStorage
    },
  },
});

export const { setUserID, clearID } = userIDSlice.actions;

export default userIDSlice.reducer;
