import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const Sidebar = styled.div`
  width: 250px;
  background-color: #ffffff;
  color: #002033;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-right: 2px solid #00416633;
`;

export const UploadButton = styled.button`
  background-color: #0078d2;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #b3b3b3;
  }
  &::before {
    content: url("/assets/icons/IconUpload.svg");
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }
`;

export const MenuIcon = styled.img`
  margin-right: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Content = styled.div`
  flex: 1;
  //padding: 0 37px 0 37px;
  background-color: #fff;
  overflow-y: auto;
`;

export const Divider = styled.div`
  width: 250px;
  height: 1px;
  background-color: #00416633;
  margin: 24px 0;
`;
