import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const YandexAuthComplete: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.hash.substring(1));
        const access_token = urlParams.get("access_token");
        const state = urlParams.get("state");

        if (access_token && state) {
            window.opener.postMessage({ access_token, state }, window.location.origin);
            window.close();
        }
    }, [location]);

    return <div>Processing...</div>;
};

export default YandexAuthComplete;
