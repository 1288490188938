import React, { useState } from "react";
import FileUploadModal from "../FileUploadModal";
import { Logo, LogoIcon, LogoText } from "../styles/PreviewPage.styles";
import { useDispatch } from "react-redux";
import { useLogoutUserMutation } from "../../redux/apiService";
import { clearTokens } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Content,
  Divider,
  Menu,
  MenuIcon,
  MenuItem,
  ModalOverlay,
  Sidebar,
  UploadButton,
} from "../styles/Layout.styles";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [logoutUser] = useLogoutUserMutation();
  const refreshToken = localStorage.getItem("refreshToken");
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Container>
        <Sidebar>
          <Logo>
            <LogoIcon
              src="/assets/icons/Subtract.svg"
              style={{ width: "24px", height: "24px" }}
            />
            <LogoText style={{ fontSize: "24px" }}>muDAARK.io</LogoText>
          </Logo>
          <UploadButton onClick={openModal}>Загрузить файл</UploadButton>
          <Menu>
            <MenuItem>
              <MenuIcon src="/assets/icons/IconDocBlank.svg" alt="Files" />
              Мои файлы
            </MenuItem>
            <MenuItem>
              <MenuIcon src="/assets/icons/IconRouble.svg" alt="Files" />
              Подписка
            </MenuItem>
            <MenuItem>
              <MenuIcon src="/assets/icons/IconSettings.svg" alt="Files" />
              Настройки
            </MenuItem>
            <Divider />
            <MenuItem>
              <MenuIcon src="/assets/icons/IconCommentFilled.svg" alt="Files" />
              Обратная связь
            </MenuItem>
            <MenuItem>
              <MenuIcon src="/assets/icons/mdi_gift.svg" alt="Files" />
              Бонусная программа
            </MenuItem>
            <MenuItem>
              <MenuIcon
                src="/assets/icons/mingcute_telegram-fill.svg"
                alt="Files"
              />
              Телеграм-бот
            </MenuItem>
            <MenuItem
              onClick={async () => {
                if (!refreshToken) return;

                try {
                  const response = await logoutUser({
                    refresh: refreshToken,
                  }).unwrap();
                  if (response.status_code === 200) {
                    // Перенаправляем пользователя
                    navigate("/login-page");
                    dispatch(clearTokens()); // Очищаем токены в Redux и localStorage
                  }
                } catch (error) {
                  console.error("Ошибка при выходе из системы:", error);
                }
              }}
            >
              <MenuIcon src="/assets/icons/IconExit.svg" alt="Files" />
              Выйти
            </MenuItem>
          </Menu>
        </Sidebar>
        <Content>{children}</Content>
      </Container>
      {isModalOpen && (
        <ModalOverlay>
          <FileUploadModal closeModal={closeModal} />
        </ModalOverlay>
      )}
    </>
  );
};

export default Layout;
