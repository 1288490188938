import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Buttons,
  Container,
  Logo,
  LogoIcon,
  LogoText,
  PrimaryButton,
  SecondaryButton,
  Subtitle,
  Title,
  Wrapper,
} from "../../components/styles/PreviewPage.styles";

const PreviewPage: React.FC = () => {
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate("/main-page");
  };

  const handleLoginClick = () => {
    navigate("/login-page");
  };

  return (
    <Wrapper>
      <Container>
        <Logo>
          <LogoIcon src="/assets/icons/Subtract.svg" />
          <LogoText>muDAARK.io</LogoText>
        </Logo>
        <Title>Расшифруем любые аудио и видео файлы в текст</Title>
        <Subtitle>
          Разделим текст на спикеров, расставим таймкоды и знаки препинания
        </Subtitle>
        <Buttons>
          <PrimaryButton onClick={handleStartClick}>
            Начать пользоваться
          </PrimaryButton>
          <SecondaryButton onClick={handleLoginClick}>
            Авторизация
          </SecondaryButton>
        </Buttons>
      </Container>
    </Wrapper>
  );
};

export default PreviewPage;
