import React, { useState } from "react";
import {
  Card,
  Container,
  Footer,
  Input,
  InputGroup,
  InputName,
  RegisterLink,
  SubmitButton,
  Title,
} from "../../components/styles/LoginPage.styles";
import {
  Logo,
  LogoIcon,
  LogoText,
} from "../../components/styles/PreviewPage.styles";
import { useNavigate } from "react-router-dom";
import { useResetPasswordRequestMutation } from "../../redux/apiService";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import RegistrationConfirmationModal from "../../components/RegistrationConfirmationModal";

const SetNewPasswordPage: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [resetPassword, { isLoading }] = useResetPasswordRequestMutation();
  const user_id = useSelector((state: RootState) => state.userID.userID);

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login-page");
  };

  const handleConfirmPassword = async () => {
    setError(null); // Сбрасываем ошибку перед новым запросом
    if (!user_id) {
      setError(
        "Ошибка: user_id не найден. Пожалуйста, начните процедуру восстановления пароля сначала.",
      );
      return;
    }

    const parsedUserId = user_id ? parseInt(user_id, 10) : null;

    if (parsedUserId === null || isNaN(parsedUserId)) {
      setError(
        "Ошибка: Не удалось получить идентификатор пользователя. Попробуйте позже.",
      );
      return;
    }

    try {
      const response = await resetPassword({
        password,
        confirm_password,
        user_id: parsedUserId,
      }).unwrap();

      if (response.status_code === 200) {
        // Перенаправляем пользователя
        //navigate("/login-page");
        setModalMessage("Пароль успешно установлен!");
        setIsSuccess(true);
        setIsModalOpen(true);
      } else {
        setError("Ошибка авторизации. Проверьте данные и попробуйте снова.");
        setModalMessage(
          "Ошибка регистрации. Проверьте данные и попробуйте снова.",
        );
        setIsSuccess(false);
        setIsModalOpen(true);
      }
    } catch (err) {
      setError(
        "Не удалось войти. Проверьте почту и пароль или повторите попытку позже.",
      );
      setModalMessage("Не удалось завершить регистрацию. Попробуйте позже.");
      setIsSuccess(false);
      setIsModalOpen(true);
    }
  };
  return (
    <Container>
      <Card>
        <Logo>
          <LogoIcon src="/assets/icons/Subtract.svg" />
          <LogoText>muDAARK.io</LogoText>
        </Logo>
        <Title>
          Для завершения процедуры необходимо придумать новый пароль
        </Title>
        <InputGroup>
          <InputName>Пароль</InputName>
          <Input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputName>Повторите пароль</InputName>
          <Input
            type="password"
            placeholder="Подтвердить пароль"
            value={confirm_password}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <SubmitButton onClick={handleConfirmPassword}>
            Зарегистрироваться
          </SubmitButton>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Footer>
            Уже есть аккаунт?
            <RegisterLink onClick={handleLogin}>Войти</RegisterLink>
          </Footer>
        </InputGroup>
      </Card>
      {isModalOpen && (
        <RegistrationConfirmationModal
          message={modalMessage || ""}
          onClose={() => {
            setIsModalOpen(false);
            if (isSuccess) navigate("/login-page");
          }}
          isSuccess={isSuccess}
        />
      )}
    </Container>
  );
};

export default SetNewPasswordPage;
