import React, { useState } from "react";
import {
  Card,
  Container,
  Footer,
  GoogleButton,
  Input,
  InputGroup,
  InputName,
  OrText,
  RegisterLink,
  SubmitButton,
  Title,
} from "../../components/styles/LoginPage.styles";
import { useNavigate } from "react-router-dom";

import { useForgotPasswordCodeRequestMutation } from "../../redux/apiService";
import {
  Logo,
  LogoIcon,
  LogoText,
} from "../../components/styles/PreviewPage.styles";
import { useDispatch } from "react-redux";
import { setUserEmail } from "../../redux/slices/emailSlice";

const ResetPasswordStep1Page: React.FC = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const [error, setError] = useState<string | null>(null);

  const [sendCode, { isLoading }] = useForgotPasswordCodeRequestMutation();

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login-page");
  };

  const handleCodeRequest = async () => {
    setError(null); // Сбрасываем ошибку перед новым запросом
    try {
      const response = await sendCode({ email }).unwrap();

      if (response.status_code === 200) {
        dispatch(setUserEmail(email)); // Сохраняем email в Redux и localStorage

        navigate("/confirmation-password-code-page");
      } else {
        setError("Пользователь не найден. Попробуйте снова.");
      }
    } catch (err) {
      setError(
        "Не удалось отправить код. Проверьте почту и повторите попытку позже.",
      );
    }
  };

  return (
    <Container>
      <Card>
        <Logo>
          <LogoIcon src="/assets/icons/Subtract.svg" />
          <LogoText>muDAARK.io</LogoText>
        </Logo>
        <Title>Восстановление пароля</Title>
        <InputGroup>
          <GoogleButton>Продолжить с помощью Google</GoogleButton>
          <OrText>ИЛИ</OrText>
          <InputName>Почта</InputName>
          <Input
            type="email"
            placeholder="Адрес электронной почты"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {error && <p style={{ color: "red" }}>{error}</p>}
          <SubmitButton onClick={handleCodeRequest} disabled={isLoading}>
            Продолжить с почтой
          </SubmitButton>
          <Footer>
            Уже есть аккаунт?
            <RegisterLink onClick={handleLogin}>Войти</RegisterLink>
          </Footer>
        </InputGroup>
      </Card>
    </Container>
  );
};

export default ResetPasswordStep1Page;
