import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto;
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;

export const ModalCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  width: 450px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Это важно для абсолютного позиционирования инпута внутри */
  z-index: 1000; /* Убедитесь, что модалка выше других элементов */
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;

  &:hover {
    color: #333;
  }
`;

/* Step 1 */
export const DropArea = styled.div`
  background-color: #f7f9fc;
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
`;

export const DragText = styled.p`
  font-size: 14px;
  color: #333;

  span {
    color: #007bff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const HintText = styled.p`
  font-size: 12px;
  color: #666;
`;

export const UploadOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const OptionButton = styled.button`
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const FromDeviceButton = styled.button`
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  font-size: 14px;
  background-color: #edf2f4;
  color: #00395ccc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative; /* Добавлено */
  overflow: hidden; /* Предотвращает выход */

  &::before {
    content: url("/assets/icons/IconFolders.svg");
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
`;
export const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1; /* Убедимся, что кнопка работает корректно */
  pointer-events: auto; /* Включим клики только в необходимых местах */
`;

export const FromLinkButton = styled.button`
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  font-size: 14px;
  background-color: #edf2f4;
  color: #00395ccc;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  //&:hover {
  //  background-color: #0056b3;
  //}
  &::before {
    content: url("/assets/icons/IconBackward.svg");
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const SubmitButton = styled.button`
  width: 40%;
  padding: 12px;
  font-size: 16px;
  background-color: #0078d2;
  color: #fff;
  border: none;
  border-radius: 8px;
  //cursor: not-allowed;
  margin-left: auto;
  &:hover {
    background-color: #bbb;
  }

  //&[disabled] {
  //  cursor: not-allowed;
  // }
`;

/* Step 2 */
export const FileDetails = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const FileIcon = styled.img`
  width: 24px;
  height: 28px;
  margin-right: 12px;
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
`;
export const FileName = styled.div`
  font-size: 14px;
  color: #333;
  font-weight: bold;
  display: inline-block; /* Необходим для работы text-overflow */
  max-width: 38ch; /* Ограничение ширины текста в символах */
  white-space: nowrap; /* Отключает перенос текста на новую строку */
  overflow: hidden; /* Скрывает лишний текст */
  text-overflow: ellipsis; /* Добавляет троеточие */
  background-color: transparent;
  border: none;
`;

export const FileMeta = styled.div`
  font-size: 12px;
  color: #666;
  text-align: left;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #333;
  text-align: left;
`;

export const Input = styled.input`
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const DeleteIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 12px;

  &:hover {
    opacity: 0.8;
  }
`;
