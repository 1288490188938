import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  background-color: #ecf1f4;
  padding: 10px 15px;
  //border-bottom: 1px solid #dcdcdc;
`;

export const HeaderItem = styled.div`
  //font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  color: #002033;

  &:first-child {
    text-align: left;
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  padding: 10px 15px;
  border-bottom: 2px solid #ccd9e0;
`;

export const Cell = styled.div`
  font-size: 14px;
  color: #002033;
  display: flex;
  align-items: center;
  text-align: left;
  //justify-content: center;
  //font-family: Inter;
  font-weight: 400;
  line-height: 19.6px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  &:first-child {
    text-align: left;
    justify-content: flex-start;
  }
`;

export const Link = styled.button`
  color: #007bff;
  text-decoration: none;
  display: inline-block; /* Необходим для работы text-overflow */
  max-width: 20ch; /* Ограничение ширины текста в символах */
  white-space: nowrap; /* Отключает перенос текста на новую строку */
  overflow: hidden; /* Скрывает лишний текст */
  text-overflow: ellipsis; /* Добавляет троеточие */
  background-color: transparent;
  border: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Name = styled.text`
  display: inline-block; /* Необходим для работы text-overflow */
  max-width: 18ch; /* Ограничение ширины текста в символах */
  white-space: nowrap; /* Отключает перенос текста на новую строку */
  overflow: hidden; /* Скрывает лишний текст */
  text-overflow: ellipsis; /* Добавляет троеточие */
  background-color: transparent;
  border: none;
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

export const DownloadButton = styled.button`
  background-color: #edf2f4;
  color: #00395ccc;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &::before {
    content: url("/assets/icons/IconDownload.svg");
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const DeleteButton = styled.button`
  background-color: #edf2f4;
  color: #00426912;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  width: 24px;
  height: 24px;

  &:hover {
    opacity: 0.9;
  }

  &::before {
    content: url("/assets/icons/Trash.svg");
    display: inline-block;
    vertical-align: center;
    horiz-align: center;
    // margin-right: 10px;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

export const EmptyIcon = styled.div`
  width: 120px;
  height: 120px;
  background: url("/assets/icons/empty_folder.svg") no-repeat center center;
  background-size: contain;
  margin-bottom: 20px;
`;

export const EmptyText = styled.p`
  font-size: 18px;
  color: #7a7a7a;
  margin-bottom: 20px;
`;

export const UploadButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;

  &:hover {
    background-color: #0056b3;
  }
`;
