import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const StatusBadge: React.FC<Props> = ({ children }) => {
  let backgroundColor;

  if (children === "completed") {
    backgroundColor = "#24C38E";
  } else if (children === "processing") {
    backgroundColor = "#F38B00";
  } else if (children === "error") {
    backgroundColor = "#EB5757";
  }

  return (
    <div
      style={{
        width: "90px",
        height: "24px",
        padding: "0px 8px 0px 8px",
        gap: "0px",
        borderRadius: "10px",
        opacity: "1",
        backgroundColor,
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: "center",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
        color: "#FAFAFA",
        textTransform: "uppercase",
      }}
    >
      {children}
    </div>
  );
};

export default StatusBadge;
