import React from "react";
import {
  Header,
  ModalCard,
  ModalContainer,
  SubmitButton,
} from "./styles/FileUploadModal.styles";

const RegistrationConfirmationModal: React.FC<{
  message: string;
  onClose: () => void;
  isSuccess: boolean | null;
}> = ({ message, onClose, isSuccess }) => (
  <ModalContainer>
    <ModalCard>
      <Header>
        <h3>{isSuccess ? "Успех!" : "Ошибка"}</h3>
        <button
          onClick={onClose}
          style={{ background: "none", border: "none" }}
        >
          ✖
        </button>
      </Header>
      <p
        style={{
          textAlign: "center",
          color: isSuccess ? "green" : "red",
          fontSize: "16px",
        }}
      >
        {message}
      </p>
      <SubmitButton onClick={onClose}>
        {isSuccess ? "Войти" : "Попробовать снова"}
      </SubmitButton>
    </ModalCard>
  </ModalContainer>
);

export default RegistrationConfirmationModal;
