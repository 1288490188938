import { createSlice } from "@reduxjs/toolkit";

const tableSlice = createSlice({
  name: "table",
  initialState: {
    shouldRefetch: false,
  },
  reducers: {
    triggerRefetch: (state) => {
      state.shouldRefetch = true;
    },
    resetRefetch: (state) => {
      state.shouldRefetch = false;
    },
  },
});

export const { triggerRefetch, resetRefetch } = tableSlice.actions;
export default tableSlice.reducer;
