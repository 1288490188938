import React, { useState } from "react";
import {
  Card,
  Container,
  Footer,
  ForgetPasswordGroup,
  Input,
  InputGroup,
  InputName,
  OrText,
  RegisterLink,
  SubmitButton,
  Title,
} from "../../components/styles/LoginPage.styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAccessToken, setRefreshToken } from "../../redux/slices/authSlice";
import { useLoginUserMutation } from "../../redux/apiService";
import {
  Logo,
  LogoIcon,
  LogoText,
} from "../../components/styles/PreviewPage.styles";
import GoogleLoginButton from "../../components/forAuthPages/GoogleLoginButton";
import axios from "axios";
import YandexLoginButton from "../../components/forAuthPages/YandexLoginButton";

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  const [loginUser, { isLoading }] = useLoginUserMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    setError(null); // Сбрасываем ошибку перед новым запросом
    try {
      const response = await loginUser({ username, password }).unwrap();

      if (response.access && response.refresh) {
        // Сохраняем токены
        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);

        // Обновляем Redux
        dispatch(setAccessToken(response.access));
        dispatch(setRefreshToken(response.refresh));

        // Перенаправляем пользователя
        navigate("/");
      } else {
        setError("Ошибка авторизации. Проверьте данные и попробуйте снова.");
      }
    } catch (err) {
      setError(
        "Не удалось войти. Проверьте почту и пароль или повторите попытку позже.",
      );
    }
  };

  // const handleGoogleLogin = () => {
  //   const backendUrl = "https://mu.daark-team.ru";
  //   window.location.href = `${backendUrl}/auth/login/google-oauth2/?next=/`;
  // };
  //
  // const handleYandexLogin = () => {
  //   const backendUrl = "https://mu.daark-team.ru";
  //   window.location.href = `${backendUrl}/auth/login/yandex-oauth2/?next=/`;
  // };

  const handleNavigateToRegistration = () => {
    navigate("/registration-page"); // Переход на страницу регистрации
  };

  const handleNavigateToResetPasswordPage = () => {
    navigate("/reset-password-step1-page");
  };

  // const handleGoogleLoginSuccess = (tokenResponse: any) => {
  //   console.log("Успешная авторизация через Google:", tokenResponse);
  // };

  // const handleGoogleLoginSuccess = (tokenResponse: any) => {
  //   console.log("Успешная авторизация через Google:", tokenResponse);
  //   // Здесь ты можешь отправить токен на сервер для валидации
  //   // Например:
  //   // axios.post('/api/auth/google', { token: tokenResponse.credential });
  // };
  // const handleGoogleLoginSuccess = async (tokenResponse: any) => {
  //   const { access_token } = tokenResponse; // Берем access_token из ответа Google
  //   const provider = "google";
  //
  //   try {
  //     const response = await axios.get(
  //       "https://mu.daark-team.ru/accounts/social-login",
  //       {
  //         access_token,
  //         provider,
  //       },
  //     );
  //
  //     if (response.data.access && response.data.refresh) {
  //       // Сохраняем токены
  //       const { access, refresh } = response.data;
  //       localStorage.setItem("accessToken", access);
  //       localStorage.setItem("refreshToken", refresh);
  //
  //       // Обновляем Redux
  //       dispatch(setAccessToken(access));
  //       dispatch(setRefreshToken(refresh));
  //
  //       console.log("Успешная авторизация через Google и сохранение токенов!");
  //       navigate("/"); // Перенаправляем пользователя
  //     } else {
  //       console.error("Ошибка: сервер не вернул токены.");
  //     }
  //   } catch (error) {
  //     console.error("Ошибка при отправке запроса на сервер:", error);
  //   }
  // };
  const handleGoogleLoginSuccess = async (tokenResponse: any) => {
    const { access_token } = tokenResponse; // Берем access_token из ответа Google
    console.log("Успешная авторизация через Google:", tokenResponse);

    try {
      const response = await axios.post(
        "https://mu.daark-team.ru/accounts/google-login/",
        JSON.stringify({ access_token }),
        {
          headers: {
            "Content-Type": "application/json", // Указываем тип содержимого
          },
        },
      );

      if (response.data.tokens.access && response.data.tokens.refresh) {
        // Сохраняем токены
        const { access, refresh } = response.data.tokens;
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);

        // Обновляем Redux
        dispatch(setAccessToken(access));
        dispatch(setRefreshToken(refresh));

        console.log("Успешная авторизация через Google и сохранение токенов!");
        navigate("/"); // Перенаправляем пользователя
      } else {
        console.error("Ошибка: сервер не вернул токены.");
      }
    } catch (error) {
      console.error("Ошибка при отправке запроса на сервер:", error);
    }
  };

  const handleYandexLoginSuccess = async (tokenResponse: any) => {
    const { access_token } = tokenResponse; // Берём access_token из ответа Яндекса
    console.log("Успешная авторизация через Яндекс:", tokenResponse);
    //const provider = "yandex";

    try {
      const response = await axios.post(
        "https://mu.daark-team.ru/accounts/yandex-login/",
        { access_token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.tokens.access && response.data.tokens.refresh) {
        // Сохраняем токены
        const { access, refresh } = response.data.tokens;
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);

        // Обновляем Redux
        dispatch(setAccessToken(access));
        dispatch(setRefreshToken(refresh));

        console.log("Успешная авторизация через Яндекс и сохранение токенов!");
        navigate("/"); // Перенаправляем пользователя
      } else {
        console.error("Ошибка: сервер не вернул токены.");
      }
    } catch (error) {
      console.error("Ошибка при отправке запроса на сервер:", error);
    }
  };

  return (
    <Container>
      <Card>
        <Logo>
          <LogoIcon src="/assets/icons/Subtract.svg" />
          <LogoText>muDAARK.io</LogoText>
        </Logo>
        <Title>Добро пожаловать!</Title>
        <InputGroup>

          <GoogleLoginButton
            onSuccess={handleGoogleLoginSuccess}
            onError={() => console.error("Ошибка авторизации через Google.")}
          />

          <YandexLoginButton
            onSuccess={handleYandexLoginSuccess}
            onError={() => console.error("Ошибка авторизации через Яндекс.")}
          />
          <OrText>ИЛИ</OrText>
          <InputName>Почта</InputName>
          <Input
            type="email"
            placeholder="Адрес электронной почты"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <ForgetPasswordGroup>
            <InputName>Пароль</InputName>
            <RegisterLink onClick={handleNavigateToResetPasswordPage}>
              Забыли пароль?
            </RegisterLink>
          </ForgetPasswordGroup>
          <Input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p style={{ color: "red" }}>{error}</p>}
          <SubmitButton onClick={handleLogin} disabled={isLoading}>
            Продолжить с почтой
          </SubmitButton>
          <Footer>
            Ещё нет аккаунта?
            <RegisterLink onClick={handleNavigateToRegistration}>
              Зарегистрируйтесь
            </RegisterLink>
          </Footer>
        </InputGroup>
      </Card>
    </Container>
  );
};

export default LoginPage;
