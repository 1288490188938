// Стилизация компонентов
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(180deg, #f7f9fc 0%, #eaf2f9 100%);
`;

export const Container = styled.div`
  text-align: center;
  max-width: 650px;
  padding: 20px;
  background-color: transparent;
  border-radius: 12px;
  //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const LogoIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const LogoText = styled.h1`
  color: #002033;
  //font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const Title = styled.h2`
  // font-family: Inter;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const Subtitle = styled.p`
  //font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 42px;
`;

export const PrimaryButton = styled.button`
  background-color: #0078d2;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const SecondaryButton = styled.button`
  background-color: #fff;
  color: #0078d2;
  padding: 12px 20px;
  border: 2px solid #0078d2;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #f0f8ff;
  }
`;
