import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(180deg, #f7f9fc 0%, #0078d233 100%);
`;

export const Card = styled.div`
  background-color: transparent;
  padding: 20px 40px;
  border-radius: 10px;
  //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: bold;
`;

export const Subtitle = styled.h2`
  font-size: 18px;
  margin: 10px 0 20px;
  color: #333;
`;

export const GoogleButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #131314;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  color: #ffffff;

  &:hover {
    background-color: #131314;
  }
  &::before {
    content: url("/assets/icons/logo googleg 48dp.svg");
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const YandexButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #131314;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  color: #ffffff;

  &:hover {
    background-color: #131314;
  }
  &::before {
    content: url("/assets/icons/Yandex_icon.svg");
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const OrText = styled.p`
  margin: 15px 0;
  font-size: 16px;
  color: #00203399;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 8px;
  //max-width: 400px;

  &:focus {
    outline: none;
    border-color: #888;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #0078d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 400px;

  &:hover {
    background-color: #555;
  }
`;

export const Footer = styled.p`
  margin-top: 32px;
  font-size: 14px;
  color: #00203399;
`;

export const RegisterLink = styled.button`
  color: #0078d2;
  font-weight: bold;
  cursor: pointer;
  border: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
`;

export const InputName = styled.div`
  //  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #00203399;
`;

export const ForgetPasswordGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-top: 15px;
  //margin-bottom: 15px;
  min-width: 100%;
  width: 400px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 400px;
  margin-top: 32px;
`;
